import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import S404 from "@/views/404.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/research',
        name: 'Research',
        component: () => import(/* webpackChunkName: "research" */ '../views/Research.vue'),
    },
    {
        path: '/research/detail/:name',
        name: 'ResearchDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "research" */ '../views/ResearchDetail.vue'),
    },
    {
        path: '/research/:area?',
        name: 'ResearchArea',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "research" */ '../views/ResearchArea.vue'),
    },
    {
        path: '/people',
        name: 'People',
        component: () => import(/* webpackChunkName: "people" */ '../views/People.vue'),
    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    },
    {
        path: '/news/:name',
        name: 'NewsDetail',
        component: () => import(/* webpackChunkName: "news" */ '../views/NewsDetail.vue'),
    },
    {
        path: '/publications',
        name: 'Publications',
        component: () => import(/* webpackChunkName: "publications" */ '../views/Publications.vue'),
    },
    {
        path: '/pub/:name',
        name: 'PublicationDetail',
        component: () => import(/* webpackChunkName: "publications" */ '../views/PublicationDetail.vue'),
    },
    {
        path: '/code',
        name: 'Code',
        component: () => import(/* webpackChunkName: "code" */ '../views/Code.vue'),
    },
    {
        path: '/code/:name',
        name: 'CodeDetail',
        component: () => import(/* webpackChunkName: "code" */ '../views/CodeDetail.vue'),
    },
    {
        path: '/shrec22',
        name: 'SHREC22',
        component: () => import(/* webpackChunkName: "shrec22" */ '../components/Markdown.vue'),
        props: { title: "SHREC 2022 Track: Open-Set 3D Object Retrieval", content: () => import(/* webpackChunkName: "shrec22" */ '../data/activity/shrec22.md'), }
    },
    {
        path: '/dataset',
        name: 'Dataset',
        component: () => import(/* webpackChunkName: "data" */ '../views/Dataset.vue'),
    },
    {
        path: '/dataset/:name',
        name: "DatasetDetail",
        component: () => import(/* webpackChunkName: "data" */ '../views/DatasetDetail.vue')
    },
    {
        path: '/data/:name',
        name: "DatasetDetail",
        component: () => import(/* webpackChunkName: "data" */ '../views/DatasetDetail.vue')
    },
    {
        path: '/activity',
        name: 'Activity',
        component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue'),
    },
    {
        path: '/activity/:name',
        name: "ActivityDetail",
        component: () => import(/* webpackChunkName: "activity" */ '../views/ActivityDetail.vue')
    },
    // {
    //     path: "/THU-EOccSR",
    //     name: "THU-EOccSR",
    //     component: () => import(/* webpackChunkName: "THU-EOccSR" */ '../components/Markdown.vue'),
    //     props: { title: "Event-enhanced Occluded image Super-Resolution dataset", content: () => import(/* webpackChunkName: "THU-EOccSR" */ '../data/dataset/THU-EOccSR/dataset.md'), }
    // },
    {
        path: '*',
        name: '404',
        component: S404,
    },
]

const router = new VueRouter({
    mode: 'history',
    // 当publicPath是形如https://xxx.yy/形式的完整域名时，base的值应该设置为pathname部分，而不能直接是完整的URL。
    // 否则的话，在router跳转时，浏览器显示的域名后面就会再带上一次完整的publicPath，变成https://example.com/https:/xxx.yy/...的样子，十分丑陋
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    base: process.env.BASE_URL!.indexOf("http") == 0 ? new URL(process.env.BASE_URL!).pathname : process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            if (to.name !== from.name) return { x: 0, y: 0 }
        }
    }
})

export default router
