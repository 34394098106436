export interface ResearchArea {
    area: string
    description: string
    subarea: [string, string][],
    img?: string
    md?: string | (() => Promise<string | typeof import("*.md")>),
}

type AreaType = { [k: string]: ResearchArea }

function state() {
    const res_zh: AreaType = {
        hypergraph: {
            area: "超图计算理论及方法",
            description: "",
            subarea: [
                ["超图计算理论", "研究超图基础数学理论，挖掘复杂关联背后的数学机理。"],
                ["超图结构优化", "研究超图结构建模与动态演化，实现高阶复杂关联的精确建模。"],
                ["超图神经网络", "研究超图神经网络模型，实现高阶关联引导的多模态数据协同表示学习方法。搭建超图神经网络框架（DeepHypergraph）。"]
            ],
            img: require("../assets/research area/hypergraph_logo_invert.png"),
            md: () => import(/* webpackChunkName: "hypergraph" */ '../data/research-area/hypergraph.md'),
        },
        tools: {
            area: "超图计算工具",
            description: "一个基于PyTorch的超图计算学习框架",
            subarea: [
                ["超图结构生成", ""],
                ["超图计算函数", "基于超图建立多类型数据行为关联，实现链路预测与推荐。建模药物及标靶之间的关系，实现精确药物挖掘。"],
                ["人工智能任务", "基于多模态数据研究聚类方法，实现弱初始化条件下自主精确聚类，为大规模文档管理及摘要提取提供技术基础。"]
            ],
            img: require('../assets/research area/intro-dhg1.png'),
            md: () => import(/* webpackChunkName: "mining" */ '../data/research-area/tools.md'),
        },
        vision: {
            area: "视觉超图计算",
            description: "面向视觉信息中的复杂关联，实现多模态视觉融合感知与增强",
            subarea: [
                ["视觉目标检测", "建模图像、点云等视觉数据中的复杂关联，基于超图计算进行精确数据配准，突破开放场景中低重叠率、噪声数据影响下的配准初始化及精度受限难题。为视觉感知及3D检索、缺陷检测等提供技术支撑。"],
                ["视觉数据配准", "研究激光雷达、相机等多传感器融合的视觉场景高精度彩色点云重建。基于点云及图像的3D重建、搭建手持式及高精度多模态场景数据采集设备。研究深度数据质量评价方法，基于超图计算实现RGB-D质量主动评价，建立了首个xxx数据库。"],
                ["视觉场景理解", "基于事件数据的视频插帧、去遮挡、深度估计、低光等异常光照下复杂场景视觉数据增强及3D重建组织建立了面向xxx的xxx数据库、面向xxx的xxx数据库及面向xxx的xxx数据库。"]
            ],
            img: require("../assets/research area/3d_logo_invert.png"),
            md: () => import(/* webpackChunkName: "vision" */ '../data/research-area/vision.md'),
        },
        medicine: {
            area: "医学超图计算",
            description: "面向多模态医学信息中的复杂关联，实现医学辅助诊断、存活预测等任务",
            subarea: [
                ["脑疾病诊断", "建模功能脑网络、结构脑网络中的高阶关联，探索高阶关联视角下的功能-结构脑网络耦合关系，首先脑疾病辅助诊断、疾病严重程度预测等任务。"],
                ["病理图分析", "建模超大像素病理图中的多维度高阶关联，从拓扑、语义等空间实现基于病理图的存活预测、肿瘤类型诊断、分子分型等任务"],
                ["多模态融合", "基于影像学、病理图、实验室检验数据、音频等多模态信息，实现高阶关联视角下的多模态信息融合，为辅助诊断等任务提供更全面的信息"]
            ],
            img: require("../assets/research area/intro-medicine.png"),
            md: () => import(/* webpackChunkName: "vision2" */ '../data/research-area/medicine.md'),
        },
    }
    const res_en: AreaType = {
        hypergraph: {
            area: "Hypergraph Computation",
            description: "",
            subarea: [
                ["Hypergraph Computation Theory", "研究超图基础数学理论，挖掘复杂关联背后的数学机理。"],
                ["Hypergraph Structure Optimization", "研究超图结构建模与动态演化，实现高阶复杂关联的精确建模。"],
                ["Hypergraph Neural Networks", "研究超图神经网络模型，实现高阶关联引导的多模态数据协同表示学习方法。搭建超图神经网络框架（DeepHypergraph）。"]
            ],
            img: require("../assets/research area/hypergraph_logo_invert.png"),
            md: () => import(/* webpackChunkName: "hypergraph" */ '../data/research-area/hypergraph_en.md'),
        },
        tools: {
            area: "DeepHyperGraph Tool",
            description: "Data fusion and data mining methods for complex associations in multimodal data",
            subarea: [
                ["Hypergraph Struture Modeling", "基于3D对象的点云、视图、网格等多模态信息，通过超图建模多模态数据管理那，进行3D对象检索与识别。基于图像及文本信息进行多媒体数据表示与检索。"],
                ["Hypergraph Computation Functions", "基于超图建立多类型数据行为关联，实现链路预测与推荐。建模药物及标靶之间的关系，实现精确药物挖掘。"],
                ["Multiple AI Tasks", "基于多模态数据研究聚类方法，实现弱初始化条件下自主精确聚类，为大规模文档管理及摘要提取提供技术基础。"]
            ],
            img: require('../assets/research area/intro-dhg1.png'),
            md: () => import(/* webpackChunkName: "mining" */ '../data/research-area/tools_en.md'),
        },
        vision: {
            area: "Visual Hypergraph",
            description: "Perception and Augmentation facing complex relationship",
            subarea: [
                ["Visual Data Registration", "建模图像、点云等视觉数据中的复杂关联，基于超图计算进行精确数据配准，突破开放场景中低重叠率、噪声数据影响下的配准初始化及精度受限难题。为视觉感知及3D检索、缺陷检测等提供技术支撑。"],
                ["3D Scene Reconstruction", "研究激光雷达、相机等多传感器融合的视觉场景高精度彩色点云重建。基于点云及图像的3D重建、搭建手持式及高精度多模态场景数据采集设备。研究深度数据质量评价方法，基于超图计算实现RGB-D质量主动评价，建立了首个xxx数据库。"],
                ["Visual Data Augmentation", "基于事件数据的视频插帧、去遮挡、深度估计、低光等异常光照下复杂场景视觉数据增强及3D重建组织建立了面向xxx的xxx数据库、面向xxx的xxx数据库及面向xxx的xxx数据库。"]
            ],
            img: require("../assets/research area/3d_logo_invert.png"),
            md: () => import(/* webpackChunkName: "vision" */ '../data/research-area/vision_en.md'),
        },
        medicine: {
            area: "Medical Hypergraph",
            description: "Perception and Augmentation facing complex relationship",
            subarea: [
                ["Brain Network Analysis", "建模图像、点云等视觉数据中的复杂关联，基于超图计算进行精确数据配准，突破开放场景中低重叠率、噪声数据影响下的配准初始化及精度受限难题。为视觉感知及3D检索、缺陷检测等提供技术支撑。"],
                ["Gigapixel WSIs Analysis", "研究激光雷达、相机等多传感器融合的视觉场景高精度彩色点云重建。基于点云及图像的3D重建、搭建手持式及高精度多模态场景数据采集设备。研究深度数据质量评价方法，基于超图计算实现RGB-D质量主动评价，建立了首个xxx数据库。"],
                ["Multimodality Fusion", "基于事件数据的视频插帧、去遮挡、深度估计、低光等异常光照下复杂场景视觉数据增强及3D重建组织建立了面向xxx的xxx数据库、面向xxx的xxx数据库及面向xxx的xxx数据库。"]
            ],
            img: require("../assets/research area/intro-medicine.png"),
            md: () => import(/* webpackChunkName: "vision2" */ '../data/research-area/medicine_en.md'),
        },
    }
    return { zh: res_zh, en: res_en }
}

export default state()