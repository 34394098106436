export interface HomepageIntroduction {
    title: string
    subtitle: string
    introduction: string
}


function state() {
    const result: HomepageIntroduction = {
        title: "智能媒体与认知实验室",
        subtitle: "清华大学，软件学院",
        introduction: "客观物理世界包含广泛的复杂相互作用，在生命科学、认知科学及信息科学等领域均是通用模式，而基于具有成对相互作用的网络结构进行刻画是常用方法。需要指出的是，从社会媒体、化学反应、生命系统到生态系统中，相互作用通常发生在三个或更多节点群组中，并不能简单地用二元关系来描述。有效地利用这些高阶交互结构能够增强我们对各类系统的建模能力，并帮助理解和预测他们的动态行为。超图是建模此类高阶交互、即高阶关联的天然有效结构。智能媒体与认知实验室主要研究人工智能、计算机视觉及数据挖掘，特别是面向复杂高阶交互结构的超图计算理论、方法及应用。具体而言，重点开展超图计算基础理论和超图神经网络方法研究，并面向计算机视觉和医学图像处理等应用领域开展视觉超图计算、医学超图计算相关探索。视觉超图计算方向包括2D/3D配准、三维实景建模、立体对象识别及基于事件数据的视觉增强等，医学超图计算方向包括高阶脑网络分析、医学辅助诊断、病理图检索与分析等。"
    }
    const result_en: HomepageIntroduction = {
        title: "Intelligent Media and Cognition Lab (iMoon Lab)",
        subtitle: "School of Software, Tsinghua University",
        introduction: "The objective physical world encompasses a vast array of complex interactions, which are general patterns across domains such as life sciences, cognitive sciences, and information sciences. Depicting these interactions through network structures characterized by pairwise interactions is a common approach. It is important to note that, from social media and chemical reactions to life systems and ecosystems, interactions often occur among groups of three or more nodes and cannot be simply described by binary relationships. Effectively leveraging these high-order interaction structures can enhance our modeling capabilities for various systems, aiding in the understanding and prediction of their dynamic behaviors. Hypergraphs are a naturally efficacious structure for modeling such high-order interactions, or high-level correlations. The Intelligent Media and Cognition Lab mainly studies artificial intelligence, computer vision, and data mining, with a particular focus on hypergraph computation theories, methods, and applications geared towards complex higher-order interaction structures. Specifically, the lab conducts research on hypergraph computation theory and hypergraph neural network methods, and explores applications in fields such as computer vision and medical image processing. The main research areas in visual hypergraph computation include 2D/3D registration, 3D scene modeling, stereoscopic object recognition, and visual data augmentation based on event data. In medical hypergraph computation, research areas include higher-order brain network analysis, medical-aided diagnosis, and pathology image retrieval and analysis."
    }
    return { zh: result, en: result_en }
}

export default state()