
import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import AppFoot from "@/components/AppFoot.vue";

@Component({
  components: { AppFoot, AppBar },
})
export default class App extends Vue {}
