
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {ResearchArea as ResearchAreaType, ResearchContent} from "@/store/research";
import _ from "lodash";

@Component
export default class ResearchArea extends Vue {
  name = "ResearchArea"
  @Prop() area?: ResearchAreaType
  @Prop(Array) contents?: ResearchContent[]
  @Prop() img?: string

  hoverIndexes: Record<string, boolean> = {}
  hoverIndex = -1
  clickIndex = -1

  get selectIndex(): number {
    if ((this.clickIndex ?? -1) !== -1) return this.clickIndex
    return this.hoverIndex
  }

  setHover(index: number, value: boolean): void {
    this.hoverIndexes[index] = value
    this.hoverIndex = _.min(_.keys(_.pickBy(this.hoverIndexes, v => v)).map(v => Number(v))) ?? -1
  }

  get content(): ResearchContent | undefined {
    if (this.contents && this.selectIndex >= 0) return this.contents[this.selectIndex]
    return undefined
  }
}
