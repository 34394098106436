
import Vue from "vue";
import { Component } from "vue-property-decorator";
import news, { News as NewsType } from "@/store/news";

@Component
export default class HomepageNews extends Vue {
  name = "HomepageNews";

  get newses(): NewsType[] {
    return this.$i18n.locale === "zh" ? news.zh : news.en;
  }

  href(news: NewsType): string | undefined {
    if (news.md) return `/news/${news.title}`;
    else if (news.href) return news.href;
  }

  get MAX_NEWS_COUNT(): number {
    if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg) return 8;
    return 10;
  }
}
