import { Publication } from "@/store/publications";

const ABBREVIATION_DICT: { [k: string]: string } = {
    "IEEE Transactions on Image Processing": "IEEE TIP",
    "IEEE Transactions on Pattern Analysis and Machine Intelligence": "IEEE TPAMI",
    "IEEE Transactions on Knowledge and Data Engineering": "IEEE TKDE",
    "IEEE Transactions on Multimedia": "IEEE TMM",
    "IEEE Transactions on Cybernetics": "IEEE TCYB",
    "IEEE Transactions on Neural Networks and Learning Systems": "IEEE TNNLS",
    "IEEE Transactions on Medical Imaging": "IEEE TMI",
    "IEEE Transactions on Affective Computing": "IEEE TAC",
    "IEEE Transactions on Industrial Electronics": "IEEE TIE",
    "IEEE Transactions on Circuits and Systems for Video Technology": "IEEE TCSVT",
    "ACM International Conference on Multimedia": "MM",
    "ACM Transactions on Multimedia Computing, Communications, and Applications": "TOMM",
    "ACM Transactions on Intelligent Systems and Technology": "TIST",
    "Medical Image Analysis": "MedIA",

    "Conference on Computer Vision and Pattern Recognition": "CVPR",
    "International Conference on Medical Image Computing and Computer-Assisted Intervention": "MICCAI",
    "ACM SIGKDD International Conference on Knowledge Discovery": "KDD",
    "AAAI Conference on Artificial Intelligence": "AAAI",
    "International Conference on Computer Vision": "ICCV",
    "International Joint Conference on Artificial Intelligence": "IJCAI",
    "Neural Information Processing Systems": "NIPS",
    "European Conference on Computer Vision": "ECCV",
    "International Conference on Learning Representations": "ICLR",
    "Engineering": "Engineering",
}

function isConference(name: string): boolean {
    const conferenceList: string[] = [
        "CVPR", "MICCAI", "KDD", "AAAI", "ICCV", "IJCAI", "MM", "NIPS", "ECCV", "ICLR"
    ]
    return conferenceList.indexOf(name) !== -1
}

export function processPublications(publications: Publication[]): void {
    for (const v of publications) {
        if (v.abbreviation === undefined && typeof v.journal === "string") {
            for (const key in ABBREVIATION_DICT) {
                if (v.journal.indexOf(key) !== -1) {
                    v.abbreviation = ABBREVIATION_DICT[key]
                    break
                }
            }
        }
        if (!v.tag && v.abbreviation) {
            v.tag = {
                content: v.abbreviation,
                // color: isConference(v.abbreviation) ? "#d60093" : "#cc99ff"
                color: isConference(v.abbreviation) ? "#d60093" : "#027AFF"
            }
        }
    }
}
