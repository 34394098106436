function state() {
  const data = {
    areas: [
      {
        name: "多模态视觉感知",
        color: "#0078d7",
        home_img: require("../assets/research/lidar.jpg"),
        icon: require("../assets/3d.png")
      },
      {
        name: "视觉内容理解",
        color: "#e55934",
        home_img: require("../assets/research/retrieval-min.jpg"),
        icon: require("../assets/understanding.png")
      },
      {
        name: "超图计算",
        color: "#764dc3",
        home_img: require("../assets/research/hypergraph-min.png"),
        icon: require("../assets/graph.png")
      },
    ],
    content: [
      {
        name: "2D/3D视觉配准",
        area: "多模态视觉感知",
        description: "研究2D图像配准、3D点云配准及图像-点云跨模态配准方法，实现数据重叠率低、开放场景等复杂环境下高鲁棒的视觉配准融合",
        img: require("../assets/research/registration-min.gif"),
        homeShow: "img"
      },
      {
        name: "视觉场景3D重建",
        area: "多模态视觉感知",
        description: "研究激光雷达、相机等多传感器融合的视觉场景高精度彩色点云重建。",
        // img: require("../assets/research/merge-min.gif"),
        homeShow: "img"
      },
      {
        name: "高动态高速场景3D重建",
        area: "多模态视觉感知",
        description: "基于动态视觉传感器等多传感器融合实现复杂光照、高速运动场景/对象立体重建。",
        // img: require("../assets/research/dvs-video-min.gif"),
        homeShow: "img"
      },
      {
        name: "3D感知装置",
        area: "多模态视觉感知",
        description: "研发高精度线激光3D相机、结构光3D相机及手持式多传感器融合的3D感知装置，实现精确3D对象/场景数据采集。",
        // img: require("../assets/research/dvs-video-min.gif"),
        homeShow: "img"
      },
      {
        name: "视觉检索",
        area: "视觉内容理解",
        description: "研究多模态/单模态3D视觉特征提取、哈希编码，实现高效视觉内容检索。",
        // img: require("../assets/research/retrieval-min.jpg"),
        homeShow: "img"
      },
      {
        name: "视觉缺陷检测",
        area: "视觉内容理解",
        description: "基于图像/点云配准，实现视觉缺陷检测。",
        // img: require("../assets/research/retrieval-min.jpg"),
        homeShow: "img"
      },
      {
        name: "200x视频插帧",
        area: "视觉内容理解",
        description: "研究基于动态视觉传感器及传统RGB相机的高速视频生成。",
        img: require("../assets/research/dvs-video-min.gif"),
        homeShow: "img"
      },
      {
        name: "视觉数据增强",
        area: "视觉内容理解",
        description: "研究遮挡、异常光照等复杂环境下的图像增强方法，实现复杂环境下的2D/3D图像/视频恢复及重建。",
        img: require("../assets/research/daizhenyu-min.gif"),
        homeShow: "img"
      },
      {
        name: "超图结构建模与优化",
        area: "超图计算",
        description: "面向高阶复杂异构关联，研究超图建模与计算理论，设计超图结构生成与优化方法，实现分类、检索、聚类及回归任务。",
        img: require("../assets/research/hypergraph-min.png"),
        homeShow: "img"
      },
      {
        name: "超图神经网络",
        area: "超图计算",
        description: "研究超图神经网络模型，实现高阶关联引导的多模态数据协同表示学习方法。",
        img: require("../assets/research/hgnn-min.jpg"),
        homeShow: "img"
      },
      {
        name: "大规模超图高效计算",
        area: "超图计算",
        description: "针对百万及更大规模数据，研究超图高效计算方法，实现低复杂度下的快速超图计算。",
        img: require("../assets/research/big-min.jpg"),
        homeShow: "img"
      },
      {
        name: "超图计算应用",
        area: "超图计算",
        description: "基于超图计算开展在视觉对象检索与识别、医学辅助诊断、链路预测与推荐系统、社区挖掘、摘要提取等任务的应用。",
        // img: require("../assets/research/big-min.jpg"),
        homeShow: "img"
      },
    ]
  }
  data.content.forEach(v => Object.assign(v, {color: data.areas.find(t => t.name === v.area)?.color ?? ""}))
  return data
}

export type ResearchArea = ReturnType<typeof state>["areas"][0]
export type ResearchContent = ReturnType<typeof state>["content"][0] & { color: string }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const obj: { areas: ResearchArea[], content: ResearchContent[] } = state() as any
export default obj
