import Vue from 'vue'
import VueI18n, { TranslateResult } from 'vue-i18n'
import ZH from './zh'
import EN from './en'

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'en',
    messages: {
        'zh': ZH,
        'en': EN
    }
})

const translate = (key: string): TranslateResult => {
    console.log(key)
    if (!key) {
        return '';
    }
    return i18n.t(key);
};

export { i18n, translate }
