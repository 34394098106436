import { processPublications } from "@/utils/publications";
import { Publication } from "@/store/publications";

// 注意！为了充分利用webpack的动态加载、减少首页的代码大小，这个页面没有调用transformBibtex函数，所以这个页面写bibtex是无效的！
function state() {
    const result: Publication[] = [
        // books
        {
            author: "Qionghai Dai, Yue Gao",
            title: "Hypergraph Computation",
            journal: "Springer, ISBN: 978-981-99-0184-5",
            year: "2023",
            href: "https://link.springer.com/book/10.1007/978-981-99-0185-2",
            tag: {
                content: "BOOK",
                color: "primary"
            }
        },
        {
            author: "Yue Gao, Qionghai Dai",
            title: "View-based 3-D Object Retrieval",
            journal: "Elsevier, ISBN: 978-0-12-802419-5",
            year: "2014",
            href: "https://ieeexplore.ieee.org/abstract/document/6756714",
            tag: {
                content: "BOOK",
                color: "primary"
            }
        },
        {
            author: "Rongrong Ji, Yue Gao, Ling-Yu Duan, Hongxun Yao, Qionghai Dai",
            title: "Learning-Based Local Visual Representation and Indexing",
            journal: "Elsevier, ISBN: 978-0-12-802409-6",
            year: "2015",
            href: "https://www.sciencedirect.com/book/9780128024096/learning-based-local-visual-representation-and-indexing",
            tag: {
                content: "BOOK",
                color: "primary"
            }
        },

        // papers
        {
            author: "Yue Gao, Shuyi Ji, Xiangmin Han, Qionghai Dai",
            title: "Hypergraph Computation",
            journal: "Engineering",
            year: "2024",
            href: "https://www.sciencedirect.com/science/article/pii/S2095809924002510",
        },
        {
            author: "Juncheng Mu, Lin Bie, Shaoyi Du, Yue Gao",
            title: "ColorPCR: Color Point Cloud Registration with Multi-Stage Geometric-Color Fusion",
            journal: "Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition",
            year: "2024",
            href: "https://openaccess.thecvf.com/content/CVPR2024/html/Mu_ColorPCR_Color_Point_Cloud_Registration_with_Multi-Stage_Geometric-Color_Fusion_CVPR_2024_paper.html",
        },
        {
            author: "Siqi Li, Zhikuan Zhou, Zhou Xue, Yipeng Li, Shaoyi Du, Yue Gao",
            title: "3D Feature Tracking via Event Camera",
            journal: "Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition",
            year: "2024",
            href: "https://openaccess.thecvf.com/content/CVPR2024/html/Li_3D_Feature_Tracking_via_Event_Camera_CVPR_2024_paper.html",
        },
        {
            author: "Jielong Yan, Yifan Feng, Shihui Ying, Yue Gao",
            title: "Hypergraph Dynamic System",
            journal: "International Conference on Learning Representations",
            year: "2024",
            href: "https://openreview.net/forum?id=NLbRvr840Q",
        },
        {
            author: "Yifan Feng, Yihe Luo, Shihui Ying, Yue Gao",
            title: "LightHGNN: Distilling Hypergraph Neural Networks into MLPs for 100x Faster Inference",
            journal: "International Conference on Learning Representations",
            year: "2024",
            href: "https://openreview.net/forum?id=lHasEfGsXL",
        },
        {
            author: "Yue Gao, Jiaxuan Lu, Siqi Li, Yipeng Li, Shaoyi Du",
            title: "Hypergraph-based Multi-View ActionRecognition using Event Cameras",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10480584",
        },
        {
            author: "Bohua Wang, Zhiqiang Tian, Aixue Ye, Feng Wen, Shaoyi Du, Yue Gao",
            title: "Generative Variational-Contrastive Learning for Self-supervised Point Cloud Representation",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10475594",
        },
        {
            author: "Yifan Feng, Jiashu Han, Shihui Ying, Yue Gao",
            title: "Hypergraph Isomorphism Computation",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2024",
            href: "https://ieeexplore.ieee.org/abstract/document/10398457",
        },
        {
            author: "Yifan Feng, Shuyi Ji, Yu-Shen Liu, Shaoyi Du, Qionghai Dai, Yue Gao",
            title: "Hypergraph-Based Multi-Modal Representation for Open-Set 3D Object Retrieval",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/abstract/document/10319392",
        },

        {
            author: "Runzhao Yao, Shaoyi Du, Wenting Cui, Aixue Ye, Feng Wen, Hongbo Zhang, Zhiqiang Tian, Yue Gao",
            title: "Hunter: Exploring High-Order Consistency for Point Cloud Registration with Severe Outliers",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/abstract/document/10246849",
        },
        {
            author: "Yue Gao, Jiaxuan Lu, Siqi Li, Nan Ma, Shaoyi Du, Yipeng Li, Qionghai Dai",
            title: "Action Recognition and Benchmark Using Event Cameras",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/document/10198747",
        },    
        {
            author: "Shengrui Li, Yining Zhao, Jun Zhang, Ting Yu, Ji Zhang, Yue Gao",
            title: "High-Order Correlation-Guided Slide-Level Histology Retrieval with Self-Supervised Hashing",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2023",
            href: "https://ieeexplore.ieee.org/document/10107814"
        },
        {
            author: "Yue Gao, Siqi Li, Yipeng Li, Yandong Guo, Qionghai Dai",
            title: "SuperFast: 200× Video Frame Interpolation via Event Camer",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/abstract/document/9962797",
        },
        {
            author: "Hai Wan, Xinwei Zhang, Yubo Zhang, Xibin Zhao, Shihui Ying, Yue Gao",
            title: "Structure Evolution on Manifold for Graph Learning",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/document/9966846",
        },
        {
            author: "Yue Gao, Yifan Feng, Shuyi Ji, Rongrong Ji",
            title: "HGNN⁺: General Hypergraph Neural Networks",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/document/9795251"
        },
        {
            author: "Donglin Di, Changqing Zou, Yifan Feng, Haiyan Zhou, Rongrong Ji, Qionghai Dai, Yue Gao",
            title: "Generating Hypergraph-based High-Order Representations of Whole-Slide Histopathological Images for Survival Prediction",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/abstract/document/9903546"
        },
        {
            author: "Yubo Zhang, Shuyi Ji, Changqing Zou, Xibin Zhao, Shihui Ying, Yue Gao",
            title: "Graph Learning on Millions of Data in Seconds: Label Propagation Acceleration on Graph using Data Distribution",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/document/9756344"
        },
        {
            author: "Yujie Wang, Chenggang Yan, Yutong Feng, Shaoyi Du, Qionghai Dai, Yue Gao",
            title: "STORM: Structure-based Overlap Matching for Partial Point Cloud Registration",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/document/9705149"
        },
        {
            author: "Yue Gao, Zizhao Zhang, Haojie Lin, Xibin Zhao, Shaoyi Du, Changqing Zou",
            title: "Hypergraph Learning: Methods and Practices",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/abstract/document/9264674/"
        },
        {
            author: "Haoyi Fan, Fengbin Zhang, Yuxuan Wei, Zuoyong Li, Changqing Zou, Yue Gao, Qionghai Dai",
            title: "Heterogeneous Hypergraph Variational Autoencoder for Link Prediction",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2022",
            href: "https://ieeexplore.ieee.org/abstract/document/9354594/"
        },
        {
            author: "Xinwei Zhang, Jianwen Jiang, Yutong Feng, Zhi-Fan Wu, Hai Wan, Mingqian Tang, Rong Jin, Yue Gao",
            title: "Grow and Merge: A Unified Framework for Continuous Categories Discovery",
            journal: "Neural Information Processing Systems",
            year: "2022",
            href: "https://arxiv.org/abs/2210.04174"
        },

        {
            author: "Yining Zhao, Chao Wen, Zhou Xue, Yue Gao",
            title: "3D Room Layout Estimation from a Single Panorama Image via Deep Manhattan Hough Transform",
            journal: "European Conference on Computer Vision",
            year: "2022",
            href: "https://link.springer.com/chapter/10.1007/978-3-031-19769-7_37"
        },
        {
            author: "Yutong Feng, Jianwen Jiang, Mingqian Tang, Rong Jin, Yue Gao",
            title: "Rethinking Supervised Pre-Training for Better Downstream Transferring",
            journal: "International Conference on Learning Representations",
            year: "2022",
            href: "https://arxiv.org/abs/2110.06014"
        },
        {
            author: "Xuancheng Zhang, Rui Ma, Changqing Zou, Minghao Zhang, Xibin Zhao, Yue Gao",
            title: "View-Aware Geometry-Structure Joint Learning for Single-View 3D Shape Reconstruction",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2021",
            href: "https://ieeexplore.ieee.org/document/9462521/"
        },
        {
            author: "Chenggang Yan, Biao Gong, Yuxuan Wei, Yue Gao",
            title: "Deep Multi-View Enhancement Hashing for Image Retrieval",
            journal: "IEEE Transactions on Pattern Analysis and Machine Intelligence",
            year: "2021",
            href: "https://ieeexplore.ieee.org/abstract/document/9007489/"
        },
        {
            author: "Xuancheng Zhang, Yutong Feng, Siqi Li Changqing Zou, Hai Wan,Xibin Zhao, Yandong Guo, Yue Gao",
            title: "View-Guided Point Cloud Completion",
            journal: "Conference on Computer Vision and Pattern Recognition",
            year: "2021",
            href: "http://openaccess.thecvf.com/content/CVPR2021/html/Zhang_View-Guided_Point_Cloud_Completion_CVPR_2021_paper.html",
        },
        {
            author: "Siqi Li, Yutong Feng, Yipeng Li, Yu Jiang, Changqing Zou, Yue Gao",
            title: "Event Stream Super-Resolution via Spatiotemporal Constraint Learning",
            journal: "International Conference on Computer Vision",
            year: "2021",
            href: "http://openaccess.thecvf.com/content/ICCV2021/html/Li_Event_Stream_Super-Resolution_via_Spatiotemporal_Constraint_Learning_ICCV_2021_paper.html",
        },
        {
            author: "Donglin Di, Shengrui Li, Jun Zhang, Yue Gao",
            title: "Ranking-Based Survival Prediction on Histopathological Whole-Slide Images",
            journal: "International Conference on Medical Image Computing and Computer-Assisted Intervention",
            year: "2020",
            href: "https://link.springer.com/chapter/10.1007/978-3-030-59722-1_41",
        },
        {
            author: "Shuyi Ji, Yifan Feng, Rongrong Ji, Xibin Zhao, Wanwan Tang, Yue Gao",
            title: "Dual Channel Hypergraph Collaborative Filtering",
            journal: "ACM SIGKDD International Conference on Knowledge Discovery",
            year: "2020",
            href: "https://dl.acm.org/doi/abs/10.1145/3394486.3403253?casa_token=qkh82yHWhNwAAAAA:DzEVuPwe3eXZ2_7szuVKZ--FpJDHTkmSWBOEWSzS_0MIg8ubTOedlI9jIVX7oc7GY-XXcluWOnFs",
        },
        {
            author: "Yifan Feng, Haoxuan You, Zizhao Zhang, Rongrong Ji, Yue Gao",
            title: "Hypergraph Neural Networks",
            journal: "AAAI Conference on Artificial Intelligence",
            year: "2019",
            href: "https://ojs.aaai.org/index.php/AAAI/article/view/4235",
        },
    ]
    processPublications(result)
    return result
}

export default state()