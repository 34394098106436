
import Vue from "vue";
import introduction, {
  HomepageIntroduction as IntroductionType,
} from "@/store/homepageIntroduction";
import { Component } from "vue-property-decorator";

@Component
export default class HomepageIntroduction extends Vue {
  name = "HomepageIntroduction";

  get intro(): IntroductionType {
    return this.$i18n.locale === "zh" ? introduction.zh : introduction.en;
  }

  CLIP_THRESHOLD = 82
  toTop = this.CLIP_THRESHOLD

  mounted(): void {
    const updateToTopValue = () => {
      const rootElem = this.$refs.root as Vue
      if (rootElem) this.toTop = rootElem.$el.getBoundingClientRect().y
      requestAnimationFrame(updateToTopValue);
    };
    updateToTopValue();
  }

  get rootStyle(): Record<string, string | undefined> {
    return {"clip-path": `inset(${Math.max(this.CLIP_THRESHOLD - this.toTop, 0)}px 0 0 0)`}
  }
}
