export default {
    ZH: {
        professor: '教授',
        visiting: '访问学者',
        postdoctoral: '博士后',
        phd: '博士生',
        master: '硕士生',
        undergraduate: '本科生',
        alumni: '校友',
    },
    EN: {
        professor: 'Professor',
        visiting: 'Visiting Scholar',
        postdoctoral: 'Postdoctoral Researcher',
        phd: 'PH.D Students',
        master: 'Master Students',
        undergraduate: 'Undergraduate Students',
        alumni: 'Alumni',
    }
}