export default {
    ZH: {
        home: '首页',
        research: '研究方向',
        people: '团队',
        publication: '论文',
        dataset: '数据',
        code: '源码',
        activity: '活动',
        news: '新闻',
    },
    EN: {
        home: 'Home',
        research: 'Research',
        people: 'People',
        publication: 'Publication',
        dataset: 'Dataset',
        code: 'Code',
        activity: 'Activity',
        news: 'News',
    }
}