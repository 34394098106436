
import Vue from "vue";
import { Component } from "vue-property-decorator";
import HomepageIntroductionBox from "@/components/HomepageIntroductionBox.vue";
import ResearchAreaCard from "@/components/ResearchAreaCard.vue";
import HomepageNews from "@/components/HomepageNews.vue";
import ResearchArea from "@/components/ResearchArea.vue";
import research, {
  ResearchArea as ResearchAreaType,
  ResearchContent,
} from "@/store/research";
import { Publication as PublicationType } from "@/store/publications";
import homepagePublications from "@/store/homepagePublications";
import HomepagePublication from "@/components/HomepagePublication.vue";

@Component({
  components: {
    HomepagePublication,
    ResearchAreaCard,
    HomepageNews,
    ResearchArea,
    HomepageIntroductionBox,
  },
})
export default class Home extends Vue {
  name = "Home";

  get areas(): ResearchAreaType[] {
    return research.areas;
  }

  researchContents(areaName: string): ResearchContent[] {
    return research.content.filter((v: ResearchContent) => v.area === areaName);
  }

  get publications(): PublicationType[] {
    return homepagePublications;
  }
}
