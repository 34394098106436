export interface News {
    title: string
    date: string // 必须是YYYY-MM-DD的格式，其中DD可选
    md?: string | (() => Promise<string | typeof import("*.md")>) // 通常是通过异步require引入一个markdown文件。（利用了markdown-loader包，实际上require markdown文件得到的就是一段html字符串，因此理论上，这里填写markdown字符串也是没有任何问题的）
    href?: string
    abstract?: string
    img?: string
    color?: string // 可以不必手工填写，会随机数值
}

type i18nNews = { zh: News[], en: News[] }

function state(): i18nNews {
    const news: News[] = [
        { title: "我们主持了Pattern Recognition期刊的特刊：Graph Foundation Model for Medical Image Analysis, 欢迎大家投稿", date: "2024-10" },
        { title: "三篇论文 (Hypergraph-Based Fuzzy Assembled Representation for Open-Set 3D Object Retrieval, Multi-scale Consistency for Robust 3D Registration via Hierarchical Sinkhorn Tree, Semi-Open 3D Object Retrieval via Hypergraph-Based Hierarchical Equilibrium Representation) 被NeurIPS 2024录用", date: "2024-09" },
        { title: "一篇论文 (Inter-Intra high-order Brain Network for ASD Diagnosis via Functional MRIs) 被MICCAI 2024录用", date: "2024-06" },
        { title: "一篇论文 (ccRCC Metastasis Prediction via Exploring High-Order Correlations on Multiple WSIs) 被MICCAI 2024提前录用", date: "2024-05" },
        { title: "一篇论文(Hypergraph Computation)被Engineering录用", date: "2024-04" },
        { title: "一篇论文(Hypergraph-based Multi-View ActionRecognition using Event Cameras)被IEEE TPAMI录用", date: "2024-03" },
        { title: "一篇论文(Generative Variational-Contrastive Learning for Self-supervised Point Cloud Representation)被IEEE TPAMI录用", date: "2024-03" },
        { title: "两篇论文(ColorPCR: Color Point Cloud Registration with Multi-Stage Geometric-Color Fusion，3D Feature Tracking via Event Camera)被CVPR 2024录用", date: "2024-03" },
        { title: "两篇论文(Hypergraph Dynamic System，LightHGNN: Distilling Hypergraph Neural Networks into MLPs for 100x Faster Inference)被ICLR2024录用", date: "2024-01" },
        { title: "一篇论文(Hypergraph Isomorphism Computation)被IEEE TPAMI录用", date: "2023-12" },
        { title: "一篇论文(Hypergraph-Based Multi-Modal Representation for Open-Set 3D Object Retrieval)被IEEE TPAMI录用", date: "2023-11" },
        { title: "一篇论文(面向大规模数据的高效超图神经网络)被中国科学: 信息科学录用", date: "2023-10" },
        { title: "一篇论文(Hierarchical Set-to-set Representation for 3D Cross-modal Retrieval)被IEEE TNNLS录用", date: "2023-10" },
        { title: "一篇论文(Penalized Flow Hypergraph Local Clustering)被IEEE TKDE录用", date: "2023-09" },
        { title: "一篇论文(Hunter: Exploring High-Order Consistency for Point Cloud Registration with Severe Outliers)被IEEE TPAMI录用", date: "2023-08" },
        { title: "一篇论文(Action Recognition and Benchmark using Event Cameras)被IEEE TPAMI录用", date: "2023-07" },
        { title: "实验室高跃副教授入选DeepTech“2022年中国智能计算科技创新人物”", date: "2023-04" },
        { title: "实验室“视觉信息复杂关联计算”项目获得中国电子学会科学技术奖自然科学一等奖", date: "2023-04" },
        { title: "一篇论文(High-Order Correlation-Guided Slide-Level Histology Retrieval with Self-Supervised Hashing)被IEEE TPAMI录用", date: "2023-04" },
        { title: "一篇论文(SuperFast: 200x Video Frame Interpolation via Event Camera)被IEEE TPAMI录用", date: "2022-11" },
        { title: "一篇论文(Structure Evolution on Manifold for Graph Learning)被IEEE TPAMI录用", date: "2022-11" },
        { title: "一篇论文(Skeleton Temporal Hypergraph Neural Networks for Person Re-Identification)被IEEE TIP录用", date: "2022-09" },
        { title: "一篇论文(Generating Hypergraph-based High-Order Representations of Whole-Slide Histopathological Images for Survival Prediction)被IEEE TPAMI录用", date: "2022-09" },
        { title: "一篇论文(Grow and Merge: A Unified Framework for Continuous Categories Discovery)被NeurIPS录用", date: "2022-07" },
        { title: "一篇论文(Hypergraph Learning: Methods and Practices)被TPAMI录用", date: "2022-07" },
        { title: "一篇论文(HGNN+: General Hypergraph Neural Networks)被IEEE TPAMI录用", date: "2022-05" },
        { title: "颜杰龙同学入选清华大学“未来学者”计划", date: "2022-04" },
        { title: "一篇论文(Graph Learning on Millions of Data in Seconds: Label Propagation Acceleration on Graph using Data Distribution)被IEEE TPAMI录用", date: "2022-03" },
        { title: "一篇论文(Rotation-Invariant Point Cloud Representation for 3-D Model Recognition)被IEEE TCYB录用", date: "2022-02" },
        { title: "一篇论文(STORM: Structure-based Overlap Matching for Partial Point Cloud Registration)被IEEE TPAMI录用", date: "2022-01" },
        { title: "一篇论文(Rethinking Supervised Pre-training for Better Downstream Transferring)被ICLR 2022录用", date: "2022-01" },
        { title: "一篇论文(Big-Hypergraph Factorization Neural Network for Survival Prediction From Whole Slide Image)被IEEE TIP录用", date: "2021-12" },
        { title: "一篇论文(Learning Representation on Optimized High-Order Manifold for Visual Classification)被IEEE TMM录用", date: "2021-12" },
        { title: "赵乙宁同学荣获2021年商汤奖学金", date: "2021-11" },
        { title: "一篇论文(Exploring Complex and Heterogeneous Correlations on Hypergraph for the Prediction of Drug-Target Interactions)被Patterns (Cell Press)录用", date: "2021-11" },
        { title: "李胜锐同学入选清华大学“未来学者”计划", date: "2021-09-14" },
        { title: "一篇论文(Visual Information Quantification for Object Recognition and Retrieval)被中国科学录用", date: "2021-09-05" },
        { title: "一篇论文(Survey on Facial Expression Recognition: History, Applications, and Challenges)被IEEE TMM录用", date: "2021-09-01" },
        { title: "一篇论文(Adaptive Hypergraph Auto-Encoder for Relational Data Clustering)被IEEE TKDE录用", date: "2021-08-25" },
        { title: "一篇论文(Event Stream Super-Resolution via Spatiotemporal Constraint Learning)被ICCV 2021录用", date: "2021-07" },
        { title: "一篇论文(Recu: Reviving the Dead Weights in Binary Neural Networks)被ICCV 2021录用", date: "2021-07" },
        { title: "从业臻等同学入选清华大学优秀毕业生、北京市毕业生", date: "2021-06-27" },
        { title: "实验室与阿里巴巴达摩院联合获得ActivityNet AVA-Kinetics比赛冠军", date: "2021-06-19" },
        { title: "一篇论文(Heterogeneous Hypergraph Variational Autoencoder for Link Prediction)被IEEE TPAMI录用", date: "2021-06-09" },
        { title: "高跃副教授入选2020年爱思唯尔中国高被引学者榜单", date: "2021-05-10" },
        { title: "一篇论文(DAN: Deep-Attention Network for 3D Shape Recognition)被IEEE TIP录用", date: "2021-05-10" },
        { title: "高跃副教授入选2021年全球最具影响力的2000名AI学者榜单", date: "2021-04-21" },
        { title: "一篇论文(3DIoUMatch: Leveraging iou prediction for semi-supervised 3d object detection)被CVPR 2021录用", date: "2021-03-01" },
        { title: "一篇论文(View-Guided Point Cloud Completion)被CVPR 2021录用", date: "2021-03-01" },
        { title: "一篇论文(View-aware geometry-structure joint learning for single-view 3D shape reconstruction)被IEEE TPAMI录用", date: "2021-02-08" },
        { title: "一篇论文(Few-shot Learning by a Cascaded Framework with Shape-constrained Pseudo Label Assessment for Whole Heart Segmentation)被IEEE TMI录用", date: "2021-01-16" },
        { title: "高跃副教授入选2020年Web of Science高被引学者榜单", date: "2021-01-10" },
        { title: "一篇论文(Special Issue on Intelligent Analysis of COVID-19 Imaging Data)被Medical Image Analysis录用", date: "2020-11-14" },
        { title: "一篇论文(Deep multi-view enhancement hashing for image retrieval)被IEEE TPAMI录用", date: "2020-11-03" },
        { title: "实验室夏清博士后入选2020年北京市科技新星计划", date: "2020-08-12" },
        { title: "高跃副教授获得清华大学年度教学优秀奖", date: "2020-07-15" },
        { title: "高跃副教授入选2020年全球最具影响力的2000名AI学者榜单", date: "2020-01-13" },
        { title: "实验室团队荣获福建省科学技术进步一等奖", date: "2019-12-30" },
        { title: "高跃副教授入选2019年Web of Science高被引学者榜单", date: "2019-12-20" },
        { title: "从业臻同学荣获2019年商汤奖学金", date: "2019-12-02" },
        { title: "张子昭同学荣获微软学者奖学金", date: "2019-11-08" },
        { title: "冯玉彤同学入选清华大学“未来学者”计划", date: "2019-06-03" },
        { title: "冯玉彤同学荣获2018年商汤奖学金", date: "2018-12-02" },
        { title: "高跃副教授入选2018年Web of Science高被引学者榜单", date: "2018-11-27" },
        { title: "张子昭等同学论文获得PCM 2018最佳学生论文奖", date: "2018-09-21" },
        { title: "实验室团队获得ActivityNet时空行为定位比赛冠军", date: "2018-06-28" },
    ]
    const news_en: News[] = [
        { title: "Pattern Recognition Special Issue Call for Papers：Graph Foundation Model for Medical Image Analysis", date: "2024-10" },
        { title: "Three papers (Hypergraph-Based Fuzzy Assembled Representation for Open-Set 3D Object Retrieval, Multi-scale Consistency for Robust 3D Registration via Hierarchical Sinkhorn Tree, Semi-Open 3D Object Retrieval via Hypergraph-Based Hierarchical Equilibrium Representation) has been accepted by NeurIPS 2024", date: "2024-09" },
        { title: "One paper (Inter-Intra high-order Brain Network for ASD Diagnosis via Functional MRIs) has been accepted by MICCAI", date: "2024-06" },
        { title: "One paper (ccRCC Metastasis Prediction via Exploring High-Order Correlations on Multiple WSIs) has been provisionally accepted by MICCAI", date: "2024-05" },
        { title: "One paper (Hypergraph Computation) has been accepted by Engineering", date: "2024-04" },
        { title: "One paper (Hypergraph-based Multi-View ActionRecognition using Event Cameras) has been accepted by IEEE TPAMI", date: "2024-03" },
        { title: "One paper (Generative Variational-Contrastive Learning for Self-supervised Point Cloud Representation) has been accepted by IEEE TPAMI", date: "2024-03" },
        { title: "Two papers (ColorPCR: Color Point Cloud Registration with Multi-Stage Geometric-Color Fusion, 3D Feature Tracking via Event Camera) have been accepted by CVPR 2024", date: "2024-03" },
        { title: "Two papers(Hypergraph Dynamic System, LightHGNN: Distilling Hypergraph Neural Networks into MLPs for 100x Faster Inference) have been accepted by ICLR 2024", date: "2024-01" },
        { title: "One paper (Hypergraph Isomorphism Computation) has been accepted by IEEE TPAMI", date: "2023-12" },
        { title: "One paper (Hypergraph-Based Multi-Modal Representation for Open-Set 3D Object Retrieval) has been accepted by IEEE TPAMI", date: "2023-11" },
        { title: "One paper (Efficient Hypergraph Neural Network on Million-level Data) has been accepted by SCIENTIA SINICA Informationis", date: "2023-10" },
        { title: "One paper (Hierarchical Set-to-set Representation for 3D Cross-modal Retrieval) has been accepted by IEEE TNNLS", date: "2023-10" },
        { title: "One paper (Penalized Flow Hypergraph Local Clustering) has been accepted by IEEE TKDE", date: "2023-09" },
        { title: "One paper (Hunter: Exploring High-Order Consistency for Point Cloud Registration with Severe Outliers) has been accepted by IEEE TPAMI", date: "2023-08" },
        { title: "One paper (Action Recognition and Benchmark using Event Cameras) has been accepted by IEEE TPAMI", date: "2023-07" },
        { title: "Dr. Gao Yue was selected as one of DeepTech's \"2022 China Intelligent Computing Technology Innovators\"", date: "2023-04" },
        { title: "The project of \"Complex Association Computation of Visual Information\" won the First Prize of Natural Science in Science and Technology Award of Chinese Institute of Electronics", date: "2023-04" },
        { title: "One paper (High-Order Correlation-Guided Slide-Level Histology Retrieval with Self-Supervised Hashing) has been accepted by IEEE TPAMI", date: "2023-04" },
        { title: "One paper (SuperFast: 200x Video Frame Interpolation via Event Camera) has been accepted by IEEE TPAMI", date: "2022-11" },
        { title: "One paper (Structure Evolution on Manifold for Graph Learning) has been accepted by IEEE TPAMI", date: "2022-11" },
        { title: "One paper (Skeleton Temporal Hypergraph Neural Networks for Person Re-Identification) has been accepted by IEEE TIP", date: "2022-09" },
        { title: "One paper (Generating Hypergraph-based High-Order Representations of Whole-Slide Histopathological Images for Survival Prediction) has been accepted by IEEE TPAMI", date: "2022-09" },
        { title: "One paper (Grow and Merge: A Unified Framework for Continuous Categories Discovery) has been accepted by NeurIPS", date: "2022-07" },
        { title: "One paper (Hypergraph Learning: Methods and Practices) has been accepted by IEEE TPAMI", date: "2022-07" },
        { title: "One paper (HGNN+: General Hypergraph Neural Networks) has been accepted by IEEE TPAMI", date: "2022-05" },
        { title: "Jielong Yan was selected into the 'Future Scholar' Program of Tsinghua University", date: "2022-04" },
        { title: "One paper (Graph Learning on Millions of Data in Seconds: Label Propagation Acceleration on Graph using Data Distribution) has been accepted by IEEE TPAMI", date: "2022-03" },
        { title: "One paper (Rotation-Invariant Point Cloud Representation for 3-D Model Recognition) has been accepted by IEEE TCYB", date: "2022-02" },
        { title: "One paper (STORM: Structure-based Overlap Matching for Partial Point Cloud Registration) has been accepted by IEEE TPAMI", date: "2022-01" },
        { title: "One paper (Rethinking Supervised Pre-training for Better Downstream Transferring) has been accepted by ICLR 2022", date: "2022-01" },
        { title: "One paper (Big-Hypergraph Factorization Neural Network for Survival Prediction From Whole Slide Image) has been accepted by IEEE TIP", date: "2021-12" },
        { title: "One paper (Learning Representation on Optimized High-Order Manifold for Visual Classification) has been accepted by IEEE TMM", date: "2021-12" },
        { title: "Yining Zhao won the 2021 SenseTime Scholarship", date: "2021-11" },
        { title: "One paper (Exploring Complex and Heterogeneous Correlations on Hypergraph for the Prediction of Drug-Target Interactions) has been accepted by Patterns (Cell Press)", date: "2021-11" },
        { title: "Shengrui Li was selected into the 'Future Scholar' Program of Tsinghua University", date: "2021-09-14" },
        { title: "One paper (Visual Information Quantification for Object Recognition and Retrieval) has been accepted by Science Chine", date: "2021-09-05" },
        { title: "One paper (Survey on Facial Expression Recognition: History, Applications, and Challenges) has been accepted by IEEE TMM", date: "2021-09-01" },
        { title: "One paper (Adaptive Hypergraph Auto-Encoder for Relational Data Clustering) has been accepted by IEEE TKDE", date: "2021-08-25" },
        { title: "One paper (Event Stream Super-Resolution via Spatiotemporal Constraint Learning) has been accepted by ICCV 2021", date: "2021-07-23" },
        { title: "One paper (Recu: Reviving the Dead Weights in Binary Neural Networks) has been accepted by ICCV 2021", date: "2021-07-23" },
        { title: "Yezhen Cong was selected into the Outstanding Graduate of Tsinghua University and the Outstanding Graduate of Beijing", date: "2021-06-27" },
        { title: "The laboratory and Alibaba Dharma Institute jointly won the championship of ActivityNet AVA-Kinetics", date: "2021-06-19" },
        { title: "One paper (Heterogeneous Hypergraph Variational Autoencoder for Link Prediction) has been accepted by IEEE TPAMI", date: "2021-06-09" },
        { title: "Yue Gao was selected into 2020 Elsevier Highly Cited Chinese Researchers", date: "2021-05-10" },
        { title: "One paper (DAN: Deep-Attention Network for 3D Shape Recognition) has been accepted by IEEE TIP", date: "2021-05-10" },
        { title: "Yue Gao was selected into the World's Most Influential AI Scholar (AI 2000) In 2021", date: "2021-04-21" },
        { title: "One paper (3DIoUMatch: Leveraging iou prediction for semi-supervised 3d object detection) has been accepted by CVPR 2021", date: "2021-03-01" },
        { title: "One paper (View-Guided Point Cloud Completion) has been accepted by CVPR 2021", date: "2021-03-01" },
        { title: "One paper (View-aware geometry-structure joint learning for single-view 3D shape reconstruction) has been accepted by IEEE TPAMI", date: "2021-02-08" },
        { title: "One paper (Few-shot Learning by a Cascaded Framework with Shape-constrained Pseudo Label Assessment for Whole Heart Segmentation) has been accepted by IEEE TMI", date: "2021-01-16" },
        { title: "Yue Gao was selected into the Web of Science Highly Cited Researchers of 2020", date: "2021-01-10" },
        { title: "One paper (Special Issue on Intelligent Analysis of COVID-19 Imaging Data)被 has been accepted by Medical Image Analysis", date: "2020-11-14" },
        { title: "One paper (Deep multi-view enhancement hashing for image retrieval) has been accepted by IEEE TPAMI", date: "2020-11-03" },
        { title: "Qing Xia was selected into the Beijing Science and Technology Star Program of 2020", date: "2020-08-12" },
        { title: "Yue Gao won the Annual Teaching Excellence Award of Tsinghua University", date: "2020-07-15" },
        { title: "Yue Gao was selected into the World's Most Influential AI Scholar (AI 2000) In 2020", date: "2020-01-13" },
        { title: "The team won the First Prize of Fujian Science and Technology Progress ", date: "2019-12-30" },
        { title: "Yue Gao was selected into the Web of Science Highly Cited Researchers of 2019", date: "2019-12-20" },
        { title: "Yezhen Cong won the 2019 SenseTime Scholarship", date: "2019-12-02" },
        { title: "Zizhao Zhang won the Microsoft Research Asia Fellowship Program", date: "2019-11-08" },
        { title: "Yutong Feng was selected into the 'Future Scholar' Program of Tsinghua University", date: "2019-06-03" },
        { title: "Yutong Feng won the 2018 SenseTime Scholarship", date: "2018-12-02" },
        { title: "Yue Gao was selected into the Web of Science Highly Cited Researchers of 2018", date: "2018-11-27" },
        { title: "Zizhao Zhang et. al won the Best Student Paper of PCM 2018", date: "2018-09-21" },
        { title: "The team won the First Prize of the ActivityNet Space time Behavior Positioning Competition", date: "2018-06-28" },
    ]
    // news.forEach(v => v.color = v.color ?? colors[md5(v.title, { asBytes: true }).pop() as number % 12])
    news.forEach(v => v.color = v.color ?? (v.title.indexOf("论文") >= 0 ? "blue" : "red"))
    news_en.forEach((v, i) => v.color = v.color ?? news[i].color)
    return { zh: news, en: news_en }
}

export default state()