import lang from './data/index';
export default {
    language: {
        name: 'English'
    },
    appbar: {
        home: lang.appbar.ZH.home,
        research: lang.appbar.ZH.research,
        people: lang.appbar.ZH.people,
        publication: lang.appbar.ZH.publication,
        dataset: lang.appbar.ZH.dataset,
        code: lang.appbar.ZH.code,
        activity: lang.appbar.ZH.activity,
        news: lang.appbar.ZH.news,
    },
    home: {
        research: lang.home.ZH.research,
        news: lang.home.ZH.news,
        more_news: lang.home.ZH.more_news,
        publication: lang.home.ZH.publication,
        more_publication: lang.home.ZH.more_publication,
    },
    people: {
        professor: lang.people.ZH.professor,
        visiting: lang.people.ZH.visiting,
        postdoctoral: lang.people.ZH.postdoctoral,
        phd: lang.people.ZH.phd,
        master: lang.people.ZH.master,
        undergraduate: lang.people.ZH.undergraduate,
        alumni: lang.people.ZH.alumni,
    }
}
