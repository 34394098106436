
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { lerp } from "@/utils/math";

@Component
export default class AppBar extends Vue {
  name = "AppBar";
  header_height = 82;
  drawer = false;

  get header_max_height(): number {
    if (this.allowScroll) return 400;
    else return this.header_height;
  }

  get allowScroll(): boolean {
    return this.$route.path === "/";
  }

  scroll = 0;
  tabs = [
    { key: "appbar.home", name: this.$t("appbar.home"), url: "/" },
    {
      key: "appbar.research",
      name: this.$t("appbar.research"),
      url: "/research",
    },
    { key: "appbar.people", name: this.$t("appbar.people"), url: "/people" },
    {
      key: "appbar.publication",
      name: this.$t("appbar.publication"),
      url: "/publications",
    },
    { key: "appbar.dataset", name: this.$t("appbar.dataset"), url: "/dataset" },
    { key: "appbar.code", name: this.$t("appbar.code"), url: "/code" },
    {
      key: "appbar.activity",
      name: this.$t("appbar.activity"),
      url: "/activity",
    },
    { key: "appbar.news", name: this.$t("appbar.news"), url: "/news" },
    // {name: "SHREC22", url: "/shrec22"},
  ];
  current_tab = null;

  mounted(): void {
    const updateScrollValue = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const scroll = (this.$refs.appbar as any)?.scrollRatio;
      if (this.allowScroll) {
        if (typeof scroll === "number") this.scroll = scroll;
      } else {
        if (this.scroll !== 0) this.scroll = 0;
      }
      requestAnimationFrame(updateScrollValue);
    };
    updateScrollValue();
  }

  get isTitleClickable(): boolean {
    return this.scroll === 0;
  }

  get style_title(): Record<string, string | undefined> {
    const top = lerp(this.scroll, 0, 40);
    const left = lerp(this.scroll, 0, 50);
    const translate_top = lerp(this.scroll, 0, -50);
    const translate_left = lerp(this.scroll, 0, -50);
    const scale = lerp(this.scroll, 1, 2);
    const cursor = this.isTitleClickable ? "pointer" : undefined;
    return {
      height: `${this.header_height - 8}px`,
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${translate_top}%, ${translate_left}%) scale(${scale})`,
      cursor: cursor,
    };
  }

  onClickTitle(): void {
    if (!this.isTitleClickable) return;
    location.href = "/";
  }

  get style_carousel(): Record<string, string> {
    const opacity = lerp(this.scroll, -0.25, 2);
    return {
      opacity: `${opacity}`,
    };
  }

  get style_subtitle_mobile(): Record<string, string> {
    let font_size = lerp(this.scroll, 0, 0.75);
    const opacity = lerp(this.scroll, -0.25, 2);
    return {
      "font-size": `${font_size}rem !important`,
      height: `${font_size}rem !important`,
      opacity: `${opacity}`,
    };
  }

  get style_slogan(): Record<string, string> {
    let font_size = lerp(this.scroll, 0, 2);
    if (this.$vuetify.breakpoint.mobile) font_size *= 0.5;
    const opacity = lerp(this.scroll, -0.25, 2);
    return {
      "margin-top": `${font_size * 0.75}rem !important`,
      "font-size": `${font_size}rem !important`,
      height: `${font_size}rem !important`,
      opacity: `${opacity}`,
      transform: this.$vuetify.breakpoint.mobile ? `scale(1.2)` : "",
    };
  }

  get style_tabs(): Record<string, string> {
    const opacity = 1;
    return {
      height: `${this.header_height - 4}px`,
      opacity: `${opacity}`,
    };
  }

  header_imgs = [require("../assets/header-min.jpg")];

  onClickI18n(): void {
    this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
    localStorage.setItem("locale", this.$i18n.locale);
    this.tabs.forEach((tab) => {
      tab.name = this.$t(tab.key);
    });
  }

  get i18nBtnStyle(): Record<string, string | undefined> {
    return {"align-self": this.$vuetify.breakpoint.mobile ? "center" : "end"}
  }
}
