export default {
    ZH: {
        research: '研究方向',
        news: '新闻',
        more_news: '更多新闻',
        publication: '代表性论文',
        more_publication: '更多论文',
    },
    EN: {
        research: 'Research',
        news: 'News',
        more_news: 'More News',
        publication: 'Selected Publications',
        more_publication: 'More Publications',
    }
}