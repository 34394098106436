import { render, staticRenderFns } from "./HomepagePublication.vue?vue&type=template&id=3c2287ab&scoped=true&"
import script from "./HomepagePublication.vue?vue&type=script&lang=ts&"
export * from "./HomepagePublication.vue?vue&type=script&lang=ts&"
import style0 from "./HomepagePublication.vue?vue&type=style&index=0&id=3c2287ab&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c2287ab",
  null
  
)

export default component.exports