
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import researchArea, {
  ResearchArea as ResearchType,
} from "@/store/researchArea";

@Component
export default class ResearchAreaCard extends Vue {
  name = "ResearchAreaCard";
  @Prop() areaName!: string;
  @Prop({type: Boolean, default: true}) showImg!: boolean;

  get research(): ResearchType {
    const r = this.$i18n.locale === "zh" ? researchArea.zh : researchArea.en;
    return r[this.areaName];
  }

  get cardWidth(): Record<string, string | undefined> {
    let width = "400px"
    if (this.$vuetify.breakpoint.mobile) width = "90vw"
    return {width}
  }
}
