import lang from './data/index';
export default {
    language: {
        name: '中文'
    },
    appbar: {
        home: lang.appbar.EN.home,
        research: lang.appbar.EN.research,
        people: lang.appbar.EN.people,
        publication: lang.appbar.EN.publication,
        dataset: lang.appbar.EN.dataset,
        code: lang.appbar.EN.code,
        activity: lang.appbar.EN.activity,
        news: lang.appbar.EN.news,
    },
    home: {
        research: lang.home.EN.research,
        news: lang.home.EN.news,
        more_news: lang.home.EN.more_news,
        publication: lang.home.EN.publication,
        more_publication: lang.home.EN.more_publication,
    },
    people: {
        professor: lang.people.EN.professor,
        visiting: lang.people.EN.visiting,
        postdoctoral: lang.people.EN.postdoctoral,
        phd: lang.people.EN.phd,
        master: lang.people.EN.master,
        undergraduate: lang.people.EN.undergraduate,
        alumni: lang.people.EN.alumni,
    }
}